// Components
import DraggableList from '@/components/ui/DraggableList'
// Utils
import { get } from 'lodash'

export default {
  name: 'RationsInputs',
  components: { DraggableList },
  props: {
    // Datos de moneda
    currency: {
      required: true,
      type: Object,
      default() {
        return {}
      }
    },
    errorMessages: {
      type: Array,
      default() {
        return []
      }
    },
    items: {
      required: true,
      type: Array,
      default() {
        return []
      }
    },
    typePrice: {
      required: true,
      type: Number,
      default: 0
    }
  },
  computed: {
    /**
     * Currency Symbol
     *
     * @return {String}
     */
    currencySymbol() {
      return get(this.currency, 'symbol', null)
    }
  },
  methods: {
    /**
     * Handle remove item
     *
     * @param {Object} item - item to remove
     */
    handleRemoveItem(item) {
      this.$emit('onRemoveRationsInputs', item)
    },
    /**
     * handle change order in rations
     *
     * @param {Array} items - items ordered
     */
    handleChangeOrder(items) {
      if (Array.isArray(items)) {
        items.map((item, index) => {
          item.order = index
          return item
        })
      }
    },
    /**
     * Handle click on "rations" buttons
     */
    handleRationsButton($event) {
      this.$emit('onClickRationsButton', $event)
    }
  }
}
