// Components
import CurrencyPosition from '@/components/ui/CurrencyPosition'
// Vuex
import { mapGetters } from 'vuex'
// Utils
import { get } from 'lodash'

export default {
  name: 'DishesListPrices',
  components: { CurrencyPosition },
  props: {
    category: {
      type: String,
      default: null
    },
    item: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  computed: {
    ...mapGetters('meta', ['rationsData']),
    /**
     * Get "rationsData" like Object
     *
     * @return {Object}
     */
    getRationDataObject() {
      return this.rationsData.reduce((sumRations, ration) => {
        const { id, ...last } = ration
        sumRations[id] = last
        return sumRations
      }, {})
    },
    /**
     * Return the array prices to draw it
     *
     * @return {Array} - array prices
     */
    getPrices() {
      const itemPrices = get(this.item, `prices[${this.category}]`, null)

      if (itemPrices === null) {
        return null
      }

      const prices = Object.entries(itemPrices).reduce((sumPrices, price) => {
        sumPrices.push({
          name:
            this.getRationDataObject[price[0]] && this.getRationDataObject[price[0]].name
              ? this.getRationDataObject[price[0]].name
              : null,
          order: price[1].order,
          price: price[1].price
        })
        return sumPrices
      }, [])

      return prices.sort((a, b) => {
        return a.order - b.order
      })
    }
  }
}
