// Components
import VuetifyTabs from '@/components/ui/VuetifyTabs'
import DishForm from '../DishForm'
import DishAllergensForm from '../DishAllergensForm'
import TranslateForm from '@/components/elements/translate/TranslateForm'
// Mixins
import addonsMixin from '@/mixins/addonsMixin'
// Utils
import { get, isNil } from 'lodash'

export default {
  name: 'DishFormByTabs',
  components: {
    VuetifyTabs
  },
  mixins: [addonsMixin],
  props: {
    // UID del producto (edición)
    id: {
      type: String,
      default: null
    },
    // UID de la categoría la que pertenezco
    categoryId: {
      type: String,
      default: null
    },
    // Modelo relacionado a la carta/menú ('places' o 'companies')
    model: {
      required: true,
      default: 'places',
      type: String
    },
    // UID del modelo en BD
    modelId: {
      required: true,
      type: String
    },
    // Pestañas "adicionales" de configuración
    tabs: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      items: []
    }
  },
  mounted() {
    this.getEveryNeededData()
  },
  methods: {
    /**
     * Establecemos/obtenemos todos los datos
     * necesarios para inicializar el componente
     */
    getEveryNeededData() {
      // Pestañas disponibles
      const basicTab = this.tabs.find((tab) => tab.id === 'basic')
      const allergensTab = this.tabs.find((tab) => tab.id === 'allergens')
      const translationTab = this.tabs.find((tab) => tab.id === 'translation')

      // Datos básicos
      if (!isNil(basicTab)) {
        this.items.push({
          label: 'Básico',
          component: DishForm,
          componentProps: {
            id: this.id,
            categoryId: this.categoryId,
            model: this.model,
            modelId: this.modelId,
            ...get(basicTab, 'options', {})
          }
        })
      }

      // Alérgenos
      if (!isNil(allergensTab)) {
        this.items.push({
          label: 'Alérgenos',
          component: DishAllergensForm,
          componentProps: { ...get(allergensTab, 'options', {}) }
        })
      }

      // Multi-idiomas
      if (!isNil(translationTab)) {
        this.items.push({
          label: 'Traducciones',
          component: TranslateForm,
          componentProps: { id: this.id, model: 'dish', ...get(translationTab, 'options', {}) }
        })
      }
    }
  }
}
