// Components
import DishesListPrices from '@/components/elements/dishes/DishesListPrices'

export default {
  name: 'DishesListItem',
  components: { DishesListPrices },
  props: {
    item: {
      type: Object,
      default() {
        return {}
      }
    },
    category: {
      type: String,
      default: null
    }
  },
  methods: {
    /**
     * Handle click on name
     *
     * @param {string} id - item id
     */
    handleClickName(id) {
      this.$emit('onClickName', id)
    }
  }
}
