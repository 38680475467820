// Constantes
import { COMPANY_TYPES, DEFAULT_LANGUAGE, MENUS_TYPES } from '@/constants'
// Components
import FormButtons from '@/components/ui/FormButtons'
import VuetifyContentLoading from '@/components/ui/VuetifyContentLoading'
import VuetifyDialog from '@/components/ui/VuetifyDialog'
import PreviewImageUploader from '@/components/ui/PreviewImageUploader'
import RationOptions from '@/components/elements/dishes/RationOptions'
import DishRationInputs from '@/components/elements/dishes/DishRationInputs'
// Mixins
import formMixin from '@/mixins/formMixin'
import uiMixin from '@/mixins/uiMixin'
// Vuelidate plugin
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
// Services
import { createDish, updateDishById, getDishById, deleteDishById } from '@/services/dish'
import { getParentCategoryByChildId } from '@/services/category'
import {
  getDefaultRations,
  getEveryRationsByPlaceId,
  getEveryRationsByCompanyId
} from '@/services/ration'
// Utils
import { get, isNil, cloneDeep, set } from 'lodash'
import { stringToNumber } from '@/utils'
// Filters
import { sanitizeHtmlContent } from '@/filters'

// Different options to prices
const priceOptions = [
  {
    id: 0,
    label: 'Sin precio'
  },
  {
    id: 1,
    label: 'Precio único'
  },
  {
    id: 2,
    label: 'Por ración'
  }
]

export default {
  name: 'DishForm',
  components: {
    FormButtons,
    PreviewImageUploader,
    VuetifyDialog,
    DishRationInputs,
    VuetifyContentLoading
  },
  mixins: [formMixin, uiMixin, validationMixin],
  props: {
    // Idiomas adicionales
    additionalLanguages: {
      type: Array,
      default() {
        return []
      }
    },
    // Posee idiomas adicionales?
    areThereAdditionalLanguages: {
      type: Boolean,
      default: false
    },
    // Mostramos las opciones de la marca
    brandOptions: {
      required: true,
      default: true,
      type: Boolean
    },
    // Datos de moneda
    currency: {
      type: Object,
      default() {
        return {}
      }
    },
    // Idioma por defecto
    defaultLanguage: {
      type: String,
      default: DEFAULT_LANGUAGE
    },
    // Id del plato (edición)
    id: {
      type: String,
      default: null
    },
    // Categoría relacionada al plato
    categoryId: {
      type: String,
      default: null
    },
    // Modelo relacionado a la carta/menú ('places' o 'companies')
    model: {
      required: true,
      default: 'places',
      type: String
    },
    // UID del modelo en BD
    modelId: {
      required: true,
      type: String
    },
    // Variables del componente padre "VuetifyTabs"
    index: {
      // Indice que ocupo dentro del componente
      type: Number,
      default: 0
    },
    itemsData: {
      // Datos que se comparten entre pestañas del componente
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      // Form fields
      formFields: {
        name: null,
        description: null,
        disabled: false,
        image: null,
        imageThumbnail: null,
        prices: null
      },
      formFieldsValidations: {
        name: {
          required: 'Campo obligatorio'
        },
        prices: {
          noEmpty: 'Debes indicar un precio'
        }
      },
      // Data Dish and Category
      dishData: null,
      currentId: this.id,
      isMenu: false,
      isTAD: false,
      menuData: null,
      // PreviewImageUploader
      maxSizeImage: {
        width: 800,
        height: 600
      },
      // Rations Dialog
      rationOptions: [],
      rationOptionsComponent: RationOptions,
      rationOptionsDialogShow: false,
      rationOptionsProps: {},
      // Price
      priceTypes: priceOptions,
      currentPriceTypes: 0,
      // Others
      processingRequest: true
    }
  },
  computed: {
    /**
     * Get "rationOptions" like Object
     *
     * @return {Object}
     */
    rationOptionsObject() {
      return this.rationOptions.reduce((sumRations, ration) => {
        const { id, ...last } = ration
        sumRations[id] = last
        return sumRations
      }, {})
    },
    /**
     * Get the labels to show in the formulary
     *
     * @return {string} - label in save button
     */
    labelSaveButton() {
      const menuType = get(this.menuData, 'type', MENUS_TYPES.place.value)
      return this.areThereAdditionalLanguages &&
        isNil(this.currentId) &&
        menuType === MENUS_TYPES.place.value
        ? 'Crear'
        : 'Guardar'
    },
    /**
     * Opciones de la columna del campo del
     * formulario "disabled"
     *
     * @return {object}
     */
    disabledColumnOptions() {
      return this.brandOptions ? { md: '5', 'offset-md': '1' } : { md: '6', 'offset-md': '3' }
    },
    /**
     * Precios del producto (para mostrar los distintos campos de texto)
     */
    priceInputs() {
      return this.formFields.prices || []
    },
    /**
     * Path where the image will be saved
     *
     * @return {string}
     */
    storagePath() {
      return `${this.modelId}/dishes`
    }
  },
  async mounted() {
    await this.getEveryNeededData()
  },
  methods: {
    /**
     * Show alert with error
     *
     * @param {string} error - error message
     */
    handleError(error) {
      this.modifyAppAlert({
        text: error,
        type: 'error',
        visible: true
      })
    },
    /**
     * When the user must click on cancel button
     */
    handleCancelButton() {
      this.hideDialog()
    },
    /**
     * When the user must click on delete button
     */
    async handleDeleteButton() {
      this.modifyAppAlert({
        actionButtonFn: async () => {
          // Loading
          this.formProcessing = true
          // Deleting...
          await this.deleteDish()
          this.hideDialog()
        },
        actionButtonText: 'Borrar',
        text: '¿Desea borrar el producto?',
        type: 'warning',
        visible: true
      })
    },
    /**
     * Show rations dialog
     */
    handleShowRationsDialog() {
      if (this.currentPriceTypes === 2) {
        this.rationOptionsDialogShow = true
      }
    },
    /**
     * Remove an ration inputs items from this.formFields.prices
     *
     * @param {Object} item - item to remove
     */
    handleRemoveRationsInputs(item) {
      this.formFields.prices = this.formFields.prices.filter((ration) => {
        return item.id !== ration.id
      })
    },
    /**
     * Handle event from component loaded
     *
     * @param {Array} rations - rations ids
     */
    handleRationOptionsDialogEventComponent(rations) {
      const formFieldRations = []

      rations.forEach((rationSelected, index) => {
        const rationIndexInFormFields = !isNil(this.formFields.prices)
          ? this.formFields.prices.findIndex((formFieldRation) => {
              return formFieldRation.id === rationSelected
            })
          : -1

        if (rationIndexInFormFields === -1) {
          if (!isNil(this.rationOptionsObject[rationSelected])) {
            formFieldRations.push({
              id: rationSelected,
              name: this.rationOptionsObject[rationSelected].name,
              order: index,
              price: stringToNumber(this.rationOptionsObject[rationSelected].price)
            })
          }
        } else {
          formFieldRations.push(this.formFields.prices[rationIndexInFormFields])
        }
      })

      this.formFields.prices = formFieldRations
    },
    /**
     * Handle event from rations dialog "accept button"
     */
    handleRationOptionsDialogAcceptButton() {
      this.rationOptionsDialogShow = false
    },
    /**
     * Handle event when the price type changes
     *
     * @param {Number} type - price type
     */
    handleCurrentPriceTypes(type) {
      if (type === 0) {
        // Sin precio
        this.formFields.prices = null
      } else if (type === 1) {
        // Precio único
        this.formFields.prices = this.parsePricesObjectToArray(getDefaultRations())
      } else if (type === 2) {
        // Por tipo de tapa
        this.formFields.prices = null
      }
    },
    /**
     * Manejador del evento, cuando se suben
     * nuevos ficheros (imágenes)
     *
     * @param {array} files - Ficheros subidos
     */
    handleImagesUploaded(files) {
      if (files.length > 0) {
        // Establecemos los valores del formulario
        // con las imágenes subidas
        files.forEach((file) => {
          if (file.thumbnail) {
            this.formFields.imageThumbnail = file.url
          } else {
            this.formFields.image = file.url
          }
        })
      } else {
        this.formFields.image = null
        this.formFields.imageThumbnail = null
      }
    },
    /**
     * Esta función es llamada desde el padre (VuetifyTabs)
     * cuando el usuario pulsa sobre alguno de las pestañas
     *
     * @param {Number} tab - pestaña donde deseamos movernos
     */
    async fnToChangeTab(tab) {
      let result = false

      try {
        if (isNil(this.currentId)) {
          // Create categorie
          result = await this.onSubmit()
        } else {
          // Update categorie
          result = this.actionSubmit()
        }

        if (result) {
          this.$parent.$parent.$parent.$emit('onChangeTab', tab)
        }
      } catch (error) {
        // show error
        this.handleError(error.message)
      } finally {
        this.formProcessing = false
      }
    },
    /**
     * Cargamos todos los datos necesarios para el formulario
     */
    async getEveryNeededData() {
      try {
        // Añadimos campos de edición del brand
        if (this.brandOptions) {
          this.addBrandFormFiels()
        }

        // Datos de la categoría padre (carta)
        this.menuData = await getParentCategoryByChildId(this.categoryId)
        const menuType = get(this.menuData, 'type', MENUS_TYPES.place.value)
        const menuPrice = get(this.menuData, 'price', null)

        if (menuType === MENUS_TYPES.place.value && menuPrice) {
          this.isMenu = true
          // No se permiten precios en el producto
          this.currentPriceTypes = 0
          this.priceTypes = priceOptions.filter((option) => option.id === 0)
        } else if (menuType === MENUS_TYPES.takeAway.value) {
          this.isTAD = true
          // No se permiten que los productos no tengan precio
          this.currentPriceTypes = 1
          this.priceTypes = priceOptions.filter((option) => option.id !== 0)
        }

        // "Rellenamos" campo "prices" para que aparezca el input
        this.handleCurrentPriceTypes(this.currentPriceTypes)

        // Datos del producto
        if (!isNil(this.currentId)) {
          // Datos del producto
          this.dishData = await this.getDishData(this.currentId)
          // Para que los datos estén disponibles para el resto de pestañas
          // del formulario, lanzamos el evento
          this.changeItemsData({
            data: this.dishData
          })
        }

        // Obtenemos raciones del modelo
        this.rationOptions = await this.getRationOptions(this.model, this.modelId)

        // Seteamos datos del formulario
        this.setFormFieldsValues(this.dishData)

        // Opciones para el diálogo de raciones
        const defaultRationsOptions = getDefaultRations()
        this.rationOptionsProps = {
          // Eliminamos las raciones por defecto
          rations: this.rationOptions.filter((ration) => {
            return isNil(defaultRationsOptions[ration.id])
          }),
          value: this.formFields.prices || []
        }
      } catch (error) {
        this.handleError(error.message)
      } finally {
        this.processingRequest = false
      }
    },
    /**
     * Get the data of current dish
     *
     * @param {String} id - UID dish
     * @return {Object}
     */
    async getDishData(id) {
      const dishData = await getDishById(id)
      return dishData
    },
    /**
     * Añadimos campos adicionales de la marca (brand) para
     * cuando este componente se use para la creación de una
     * carta o categoría que depende
     */
    addBrandFormFiels() {
      this.formFields = {
        ...this.formFields,
        editabled: true
      }
    },
    /**
     * Obtenemos las raciones del modelo donde nos
     * encontramos
     *
     * @param {string} model - Modelo asociado al menú
     * @param {string} modelId - UID del Modelo asociado al menú
     * @return {object} - Objeto con las raciones asociadas
     */
    async getRationOptions(model, modelId) {
      const defaultRations = getDefaultRations()
      const rations =
        model === 'places'
          ? await getEveryRationsByPlaceId(modelId)
          : await getEveryRationsByCompanyId(modelId)

      // Lo convertimos en un array para tratarlo mejor
      return Object.entries(defaultRations).reduce((accRations, ration) => {
        accRations.push({
          id: ration[0],
          ...ration[1]
        })
        return accRations
      }, rations)
    },
    /**
     * Establece los datos del producto y sus precios
     * en los campos del formulario
     *
     * @param {object} data - datos del plato
     */
    async setFormFieldsValues(data = {}) {
      const prices = get(data, `prices[${this.categoryId}]`, null)
      const parsedPricesToArray = this.parsePricesObjectToArray(prices)

      // Seteamos campos del formulario
      this.formFields = {
        name: get(data, 'name', this.formFields.name),
        description: sanitizeHtmlContent(get(data, 'description', this.formFields.description), []),
        disabled: get(data, 'disabled', this.formFields.disabled),
        image: get(data, 'image', this.formFields.image),
        imageThumbnail: get(data, 'imageThumbnail', this.formFields.imageThumbnail),
        // Establecemos los precios con los nombres de las raciones
        prices: Array.isArray(parsedPricesToArray)
          ? parsedPricesToArray.reduce((accItems, item) => {
              const rationIndex = this.rationOptions.findIndex((ration) => {
                return ration.id === item.id
              })

              if (rationIndex > -1) {
                accItems.push({
                  ...item,
                  name: this.rationOptions[rationIndex].name
                })
              }

              return accItems
            }, [])
          : null
      }

      // price type
      if (isNil(prices)) {
        this.currentPriceTypes = 0 // Sin precio
      } else if (!isNil(prices) && prices[0]) {
        this.currentPriceTypes = 1 // Precio único
      } else if (!isNil(prices) && !prices[0]) {
        this.currentPriceTypes = 2 // Precio tipo ración
      }

      // Datos de marca
      if (this.brandOptions) {
        this.formFields.editabled = get(data, 'editabled', this.formFields.editabled)
      }
    },
    /**
     * Is triggering after the form is correctly
     * validated by "Vuelidate"
     */
    async afterSubmit() {
      if (isNil(this.currentId)) {
        // Creamos producto
        await this.createProduct()
      } else {
        // Actualizamos plato
        await this.updateProduct()
      }
      // Emitimos evento general para que cualquier lista
      // a la escucha, recargue su listado
      this.$root.$emit('reload-list')
    },
    /**
     * Procesos para la creación de nuevo producto
     */
    async createProduct() {
      // Datos del plato
      const { description, ...others } = this.formFields
      // Obtenemos raciones de "DishRationInputs"
      const dishRationInputsItems = get(this.$refs, 'dishRationInputs.items', null)
      const prices =
        !this.isMenu && !isNil(dishRationInputsItems) ? cloneDeep(dishRationInputsItems) : null
      // Datos a salvar en BD
      const dataToSave = {
        ...others,
        description: sanitizeHtmlContent(description, []),
        // propietario del producto
        ...(this.brandOptions ? { owner: COMPANY_TYPES.brand } : {}),
        prices: !isNil(prices) ? this.parsePricesArrayToObject(prices) : null
      }

      // Creamos plato
      const { dish } = await createDish(
        {
          ...dataToSave,
          categories: [this.categoryId]
        },
        null,
        {
          additionalLanguages: this.additionalLanguages,
          defaultLanguage: this.defaultLanguage
        }
      )

      // Establecemos el nuevo ID del plato
      this.currentId = dish.id
      // Establecemos los nuevos datos del plato
      this.dishData = dish

      // Modificamos "itemsData" del componente padre (VuetifyTabs)
      this.changeItemsData({
        data: this.dishData
      })

      // show info
      this.modifyAppAlert({
        text: 'Los cambios se guardaron correctamente',
        visible: true
      })
    },
    /**
     * Procesos para la actualización de un producto
     */
    async updateProduct() {
      // Datos del formulario
      const { description, ...others } = this.formFields
      // Obtenemos raciones de "DishRationInputs"
      const dishRationInputsItems = get(this.$refs, 'dishRationInputs.items', null)
      const prices =
        !this.isMenu && !isNil(dishRationInputsItems) ? cloneDeep(dishRationInputsItems) : null
      // Datos para salvar
      const dataToSave = {
        ...others,
        description: sanitizeHtmlContent(description, []),
        prices: !isNil(prices) ? this.parsePricesArrayToObject(prices) : null
      }

      // Actualizamos producto
      await updateDishById(
        {
          id: this.id,
          ...dataToSave
        },
        {
          additionalLanguages: this.additionalLanguages,
          defaultLanguage: this.defaultLanguage
        }
      )

      // Modificamos "itemsData" del componente padre (VuetifyTabs)
      this.changeItemsData({
        data: { ...this.dishData, ...dataToSave }
      })

      // show info
      this.modifyAppAlert({
        text: 'Los cambios se guardaron correctamente',
        visible: true
      })
    },
    /**
     * Different actions to delete the categories
     * and every associated to it
     */
    async deleteDish() {
      try {
        // Finalmente eliminamos el registro
        const { ok } = await deleteDishById(this.dishData.id, this.categoryId)

        if (!ok) {
          throw new Error('Hubo un error al intentar eliminar el plato.')
        }
        // Close modal
        this.hideDialog()
      } catch (error) {
        // show error
        this.handleError(error.message)
      } finally {
        // Evento general para que cualquier lista
        // a la escucha, recargue su listado
        this.$root.$emit('reload-list')
      }
    },
    /**
     * Parse "prices" variable to validate with Vue validate
     *
     * @param {Object} prices - prices value
     * @return {Array} - array to work with Vue validate
     */
    parsePricesObjectToArray(prices) {
      if (isNil(prices)) {
        return prices
      }

      if (typeof prices === 'object' && Object.keys(prices).length) {
        const parsePrices = Object.entries(prices).reduce((sumRations, ration, index) => {
          sumRations.push({
            id: ration[0],
            name:
              this.rationOptionsObject &&
              this.rationOptionsObject[ration[0]] &&
              this.rationOptionsObject[ration[0]].name
                ? this.rationOptionsObject[ration[0]].name
                : '',
            order: typeof ration[1].order !== 'undefined' ? ration[1].order : index,
            price: stringToNumber(ration[1].price)
          })
          return sumRations
        }, [])

        return parsePrices.sort((a, b) => {
          return a.order - b.order
        })
      }

      return []
    },
    /**
     * Parse "prices" to save in the right format
     *
     * @param {Array} prices - prices prices
     * @return {Object} - array to save in database
     */
    parsePricesArrayToObject(prices) {
      const everyPrice = this.dishData && this.dishData.prices ? this.dishData.prices : {}
      const parsePrices =
        !isNil(prices) && Array.isArray(prices)
          ? prices.reduce((sumRations, ration, index) => {
              sumRations[ration.id] = {
                order: index,
                price: stringToNumber(ration.price)
              }
              return sumRations
            }, {})
          : null

      everyPrice[this.categoryId] =
        parsePrices !== null && Object.keys(parsePrices).length > 0 ? parsePrices : null

      return everyPrice
    },
    /**
     * Hide dialog
     */
    hideDialog() {
      this.modifyAppDialog({
        visible: false
      })
    },
    /**
     * Modificamos el atributo "itemsData" del componente padre
     *
     * @param {Object} data - datos a emitir
     */
    changeItemsData(data) {
      this.$parent.$parent.$parent.$emit('onChangeItemsData', this.index, data)
    }
  },
  // Validations with Vuelidate
  validations() {
    const that = this

    const rules = {
      formFields: {
        name: {
          required
        },
        description: {},
        disabled: {},
        image: {},
        imageThumbnail: {},
        prices: {
          noEmpty: (value) => {
            return that.currentPriceTypes === 0
              ? true
              : !isNil(value) && value.every((v) => !isNil(v.price) && v.price > 0)
          }
        }
      }
    }

    // Validación de opciones de marca
    if (this.brandOptions) {
      set(rules, 'formFields.editabled', {})
    }

    return rules
  }
}
