// Storage
import { uploadFile, deleteFile, refFromUrl, getDownloadURLFromRef } from '@/services/storage'
// Mixins
import uiMixin from '@/mixins/uiMixin'
// Components
import FileUploader from '../FileUploader'
// Utils
import { isNil } from 'lodash'
const uniqid = require('uniqid')

export default {
  name: 'PreviewImageUploader',
  model: {
    prop: 'imageUrl',
    event: 'imagesUploaded'
  },
  components: { FileUploader },
  mixins: [uiMixin],
  props: {
    enabledResizer: {
      type: Boolean,
      default: true
    },
    imageUrl: {
      type: String,
      default: ''
    },
    dimensionBox: {
      type: Object,
      default() {
        return {
          width: 150,
          height: 150
        }
      }
    },
    labelBox: {
      type: String,
      default: 'Sin imagen'
    },
    labelInfo: {
      type: String,
      default: ''
    },
    maxSizeImage: {
      type: Object,
      default() {
        return {
          width: 1280,
          height: 720
        }
      }
    },
    storagePath: {
      type: String,
      default: ''
    },
    thumbnail: {
      type: Boolean,
      default: false
    },
    thumbnailImageUrl: {
      type: String,
      default: ''
    },
    thumbnailSize: {
      type: Object,
      default() {
        return {
          width: 100,
          height: 100
        }
      }
    }
  },
  data() {
    return {
      currentImages: [],
      processingRequest: false
    }
  },
  computed: {
    /**
     * Get the URL to preview the image
     *
     * @return {string}
     */
    currentImageUrl() {
      return this.currentImages.reduce((sumImages, image) => {
        if (image.url && !image.thumbnail) {
          sumImages = image.url
        }
        return sumImages
      }, null)
    },
    /**
     * Get the custom styles to "box"
     */
    stylesBox() {
      let style = ''

      if (this.currentImageUrl) {
        style += `background-image:url(${this.currentImageUrl});`
      }

      if (this.dimensionBox && this.dimensionBox.width && this.dimensionBox.height) {
        style += `width:${this.dimensionBox.width}px;height:${this.dimensionBox.height}px;`
      }

      return style
    }
  },
  watch: {
    imageUrl: {
      handler(value) {
        if (!isNil(value) && value !== '') {
          this.currentImages.push({
            url: value,
            thumbnail: false
          })
        }
      },
      immediate: true
    },
    thumbnailImageUrl: {
      handler(value) {
        if (!isNil(value) && value !== '') {
          this.currentImages.push({
            url: value,
            thumbnail: true
          })
        }
      },
      immediate: true
    }
  },
  methods: {
    /**
     * handle the current button action
     */
    handleUpdatePreviewImage() {
      if (!isNil(this.currentImageUrl)) {
        this.handleActionOnPreviewImage(true)
      } else {
        this.$refs.fileUploader.handleClick()
      }
    },
    /**
     * Handle remove preview image
     */
    handleRemovePreviewImage() {
      this.handleActionOnPreviewImage(false)
    },
    /**
     * Get the files that the user wants to upload
     *
     * @param {string} error - error to show
     */
    handleError(error) {
      this.modifyAppAlert({
        type: 'error',
        text: error.message,
        visible: true
      })
    },
    /**
     * Get the files that the user wants to upload
     *
     * @param {Boolean} loading - there are some files in processing?
     */
    handleProcessingFiles(loading) {
      this.processingRequest = loading
    },
    /**
     * Get the files that the user wants to upload
     *
     * @param {Array} files - files array to upload
     */
    async handleUploadFiles(files) {
      // upload images to server
      this.uploadImagesToServer(files)
    },
    /**
     * Handle preview image
     *
     * @param {Boolean} update - open file dialog
     */
    async handleActionOnPreviewImage(update = false) {
      this.modifyAppAlert({
        actionButtonFn: async () => {
          // show loading
          this.processingRequest = true
          // Remove from server
          await this.delFilesInServer(this.currentImages)
          // Set user's action
          this.userDoesAnAction = true
          // Remove from local
          this.currentImages = []

          // Para no fastidiar otros componentes que usan "v-model"
          // con este componente y no desean thumbnail
          if (this.thumbnail) {
            this.$emit('imagesUploaded', this.currentImages)
          } else {
            this.$emit('imagesUploaded', null)
          }

          this.processingRequest = false

          // Re-open dialog files
          if (update) {
            this.$refs.fileUploader.handleClick()
          }
        },
        actionButtonText: update ? 'Cambiar' : 'Borrar',
        text: update ? '¿Desea cambiar la imagen?' : '¿Desea borrar la imagen?',
        type: 'warning',
        visible: true
      })
    },
    /**
     * Upload files to server (Firebase)
     *
     * @param {Array} files - files BLOB
     */
    async uploadImagesToServer(files) {
      try {
        // Show loading
        this.processingRequest = true

        // Path to upload and name
        const fileName = uniqid()
        const path = this.storagePath || uniqid()

        // Subimos cada uno de los ficheros
        this.currentImages = await Promise.all(
          files.map(async (file) => {
            // Ruta en storage
            const storagePath = file.thumbnail
              ? `${path}/${fileName}-${this.thumbnailSize.width}x${this.thumbnailSize.height}`
              : `${path}/${fileName}`
            const fileRef = await uploadFile(file, storagePath)
            const fileUrl = await getDownloadURLFromRef(fileRef)

            return {
              ref: fileRef,
              url: fileUrl,
              thumbnail: file.thumbnail
            }
          })
        )

        // Para no fastidiar otro componentes que usan el sistema de v-model
        // con este componente y no desean thumbnail
        if (this.thumbnail) {
          this.$emit('imagesUploaded', this.currentImages)
        } else {
          this.$emit('imagesUploaded', this.currentImages[0].url)
        }
      } catch (error) {
        this.handleError(error.message)
      } finally {
        // Hide loading
        this.processingRequest = false
      }
    },
    /**
     * Delete file in server (Firebase)
     *
     * @param {array} files - files to delete
     */
    async delFilesInServer(files) {
      try {
        // Eliminamos ficheros
        await Promise.all(
          files.map(async (file) => {
            const fileRef = await refFromUrl(file.url)
            await deleteFile(fileRef)
          })
        )
      } catch (error) {
        this.handleError(error.message)
      }
    }
  }
}
