// Components
import CurrencyPosition from '@/components/ui/CurrencyPosition'
// Utils
import { isNil } from 'lodash'

export default {
  name: 'RationOptions',
  components: { CurrencyPosition },
  props: {
    value: {
      type: Array,
      default() {
        return []
      }
    },
    // Raciones a mostrar
    rations: {
      required: true,
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      selected: []
    }
  },
  watch: {
    value: {
      handler(newValue, oldValue) {
        const atLeastOneValueIsEmpty =
          (!isNil(newValue) && isNil(oldValue)) || (isNil(newValue) && !isNil(oldValue))
        const hasDifferentLength =
          Array.isArray(newValue) && Array.isArray(oldValue) && newValue.length !== oldValue.length
        const haveDifferentValues =
          Array.isArray(newValue) &&
          Array.isArray(oldValue) &&
          newValue.some((item) => {
            const indexOldValue = oldValue.findIndex((value) => {
              return value.id === item.id
            })

            return indexOldValue === -1
          })

        if (atLeastOneValueIsEmpty || hasDifferentLength || haveDifferentValues) {
          this.setSelectedValues()
        }
      },
      immediate: true
    },
    selected(value) {
      // Este evento es lanzado para todo aquel componente
      // que ha sido incluido bajo el component "VuetifyDialog"
      this.$emit('onEventComponent', value)
    }
  },
  methods: {
    /**
     * Set the initial selected values
     */
    setSelectedValues() {
      this.selected =
        typeof this.value !== 'undefined' && this.value !== null
          ? this.value.reduce((sumRations, ration) => {
              sumRations.push(ration.id)
              return sumRations
            }, [])
          : []
    }
  }
}
