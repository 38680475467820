// Constants
import { DEFAULT_LANGUAGE } from '@/constants'
// Components
import CardContainer from '@/components/ui/CardContainer'
import DishFormByTabs from '@/components/elements/dishes/DishFormByTabs'
import DishesListItem from '@/components/elements/dishes/DishesListItem'
import DishesListKeys from '@/components/elements/dishes/DishesListKeys'
import DraggableList from '@/components/ui/DraggableList'
import SectionInformationBox from '@/components/ui/SectionInformationBox'
import VuetifyContentLoading from '@/components/ui/VuetifyContentLoading'
import VuetifyToolBar from '@/components/ui/VuetifyToolBar'
// Mixins
import uiMixin from '@/mixins/uiMixin'
import addonsMixin from '@/mixins/addonsMixin'
// Services
import { getEveryDishesByCategoryId, updateDishById } from '@/services/dish'
import { getCategoryById, getParentCategoryByChildId } from '@/services/category'
import { mapState, mapGetters } from 'vuex'
// Utils
import { getPathImage } from '@/utils'
import { get, isNil } from 'lodash'

export default {
  name: 'DishesList',
  components: {
    CardContainer,
    DraggableList,
    DishesListItem,
    DishesListKeys,
    SectionInformationBox,
    VuetifyContentLoading,
    VuetifyToolBar
  },
  mixins: [uiMixin, addonsMixin],
  data() {
    return {
      id: this.$route.params.id || null, // categoryId
      category: null,
      items: [],
      parentCategory: null,
      isMenu: false,
      menuData: null,
      processingRequest: true
    }
  },
  computed: {
    ...mapState('app', ['extraSmallDevice']),
    ...mapGetters('company', ['companyData', 'companyDataConfig', 'areThereAdditionalLanguages']),
    /**
     * Get the options to show the screen when there are not items
     */
    sectionInformationOptions() {
      return {
        media: getPathImage('svg/dishes.svg'),
        title: 'Productos de la categoría',
        description: 'Aún no has creado ningún producto',
        buttonLabel: 'Crear producto ¡ahora!'
      }
    },
    /**
     * Get the current title of current view
     *
     * @return {String} - title to show in toolbar
     */
    toolbarTitle() {
      return this.parentCategory ? `${this.parentCategory.name} / Productos` : 'Productos'
    },
    /**
     * La "toolBar" es sticky
     *
     * @return {Boolean}
     */
    toolBarIsSticky() {
      return this.extraSmallDevice
    }
  },
  async mounted() {
    // Capturamos evento
    this.$root.$on('reload-list', this.setItemsList)
    // Obtenemos los datos iniciales
    await this.getEveryNeededData()
  },
  destroyed() {
    // Paramos escucha
    this.$root.$off('reload-list')
  },
  methods: {
    /**
     * Show alert with error
     *
     * @param {string} error - error message
     */
    handleError(error) {
      this.modifyAppAlert({
        text: error,
        type: 'error',
        visible: true
      })
    },
    /**
     * handle change order in items
     *
     * @param {Array} items - items ordered
     */
    async handleChangeOrder(items) {
      if (Array.isArray(items)) {
        try {
          await Promise.all(
            items.map(async (item, index) => {
              await updateDishById({
                id: item.id,
                order: index
              })
            })
          )
        } catch (error) {
          this.handleError(error.message)
        }
      }
    },
    /**
     * Abre un "dialog" para crear/editar un plato
     *
     * @param {string} id - UID category en base de datos
     */
    handleDishFormDialog(id = null) {
      // Pestañas adicionales de configuración a mostrar en el formulario
      const tabs = [
        {
          id: 'basic',
          options: {
            additionalLanguages: get(this.companyDataConfig, 'additionalLanguages', []),
            areThereAdditionalLanguages: this.areThereAdditionalLanguages,
            currency: get(this.companyDataConfig, 'currency', null),
            brandOptions: true, // para no mostrar las opciones de la marca
            defaultLanguage: get(this.companyDataConfig, 'defaultLanguage', DEFAULT_LANGUAGE)
          }
        },
        {
          id: 'allergens'
        }
      ]

      // Pestaña de traducciones
      if (this.areThereAdditionalLanguages) {
        tabs.push({
          id: 'translation',
          options: {
            additionalLanguages: get(this.companyDataConfig, 'additionalLanguages', []),
            defaultLanguage: get(this.companyDataConfig, 'defaultLanguage', DEFAULT_LANGUAGE)
          }
        })
      }

      this.modifyAppDialog({
        title: isNil(id) ? 'Crear producto' : 'Editar producto',
        contentComponent: DishFormByTabs,
        contentComponentProps: {
          id: id || null,
          categoryId: this.id,
          model: 'companies',
          modelId: this.companyData.id,
          tabs
        },
        hideActionButtons: true,
        visible: true
      })
    },
    /**
     * Get all dishes from a category
     *
     * @param {string} id - parent categorie
     */
    async getEveryNeededData() {
      this.processingRequest = true
      try {
        const category = await getCategoryById(this.id)

        if (!category) {
          throw new Error('No existe la categoría indicada')
        }

        // Set parent category data
        this.parentCategory = await getCategoryById(this.id)
        // Set menu data (Grand parent category)
        this.menuData = await getParentCategoryByChildId(this.id)
        // Set items
        await this.setItemsList()
      } catch (error) {
        this.handleError(error.message)
      } finally {
        this.processingRequest = false
      }
    },
    /**
     * Establecemos los elementos del listado
     */
    async setItemsList() {
      try {
        // Loading
        this.processingRequest = true
        // Clean the items
        this.items = []
        // Obtenemos los items (platos) del listado
        this.items = await getEveryDishesByCategoryId(this.id)
      } catch (error) {
        this.handleError(error.message)
      } finally {
        this.processingRequest = false
      }
    }
  }
}
